<template>
  <div style="position: relative; height: 100%">
      <div id="project">
          <!--<Spin size="large" v-if="SpinShou"></Spin>-->
          <!--高德地图容器-->
          <div class="centerMap"
               id="centerMap"
               v-show="!Shou3D"></div>
          <!--3D地图容器-->
          <div class="center3DMap"
               id="center3DMap"
               v-show="Shou3D"></div>
          <!--高德操作图标-->
          <div class="mapControl"
               v-show="!Shou3D">
              <div class="item"
                   @click="searchShowFn"
                   ref="searchBar">
                  <img src="../../../assets/img/Icon/search.png"
                       alt />
              </div>
              <div class="item"
                   ref="measureBar">
                  <img src="../../../assets/img/Icon/measure.png"
                       @click="measureFn"
                       alt />
                  <div class="measureBox"
                       v-if="measureShow">
                      <div ref="stadiometry"
                           @click="stadiometryFn">
                          <span>测距</span>
                      </div>
                      <div ref="mSurface"
                           @click="mSurfaceFn">
                          <span>测面</span>
                      </div>
                  </div>
              </div>
              <div class="item"
                   ref="hierarchyBar">
                  <img src="../../../assets/img/Icon/hierarchy.png"
                       @click="hierarchyFn"
                       alt />
                  <div class="hierarchyBox"
                       v-if="hierarchyShou">
                      <div class="clearfix">
                          <label>
                              <input type="radio"
                                     v-model="mapStyle1"
                                     value="平面" />
                              <span>平面</span>
                          </label>
                      </div>
                      <div class="clearfix">
                          <label>
                              <input type="radio"
                                     v-model="mapStyle1"
                                     value="卫星" />
                              <span>卫星</span>
                          </label>
                      </div>
                      <hr />
                      <div class="clearfix">
                          <label>
                              <input type="checkbox"
                                     value="路网图"
                                     v-model="mapStyle2" />
                              <span>路网图</span>
                          </label>
                      </div>
                      <div class="clearfix">
                          <label>
                              <input type="checkbox"
                                     value="地名"
                                     v-model="mapStyle2" />
                              <span>地名</span>
                          </label>
                      </div>
                      <div class="clearfix"
                           style="margin-bottom: 0">
                          <label>
                              <input type="checkbox"
                                     value="禁飞区"
                                     v-model="mapStyle2" />
                              <span>禁飞区</span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="item"
                   @click="zoomIn">
                  <img src="../../../assets/img/Icon/jia.png"
                       alt />
              </div>
              <div class="item"
                   @click="zoomOut">
                  <img src="../../../assets/img/Icon/jian.png"
                       alt />
              </div>
          </div>
          <!--搜索框-->
          <div class="searchBox">
              <i-input v-if="searchShow"
                       v-model="searchMsg"
                       autosize
                       prefix="ios-search"
                       placeholder="搜索或输入您的地点"
                       @keyup.enter.native="search" />
          </div>
          <!--3D地图切换图标-->
          <div :id="Shou3D ? 'Map3D' : ''"
               class="MapSwitch"
               ref="MapSwitch"
               v-if="MapSwitchIconShou"
               @click="Map3DSwitch">
              <img src="../../../assets/img/Icon/Switch3dMap.png"
                   alt="" />
          </div>
          <!--展开条-->
          <div class="unfoldBth"
               ref="unfoldBth"
               @click="unfold">
              <!--<img src="../../assets/img/Icon/unfold.png" alt="">-->
              <i class="ivu-icon ivu-icon-ios-arrow-back"
                 v-if="unfoldStatus"></i>
              <i class="ivu-icon ivu-icon-ios-arrow-forward"
                 v-if="!unfoldStatus"></i>
          </div>
          <!--左方测栏-->
          <div class="leftBar"
               ref="leftBar"
               v-if="leftBarShou">
<!--              <router-view :ShowMarker="ShowMarker"
                           :shouAirRoute="shouAirRoute"
                           :shou3DAirRoute="shou3DAirRoute"
                           :close3DMap="close3DMap"></router-view>-->
<!--            <router-view :DateMarker="DateMarker"></router-view>-->
              <ProjectBar_Level_2   @searchTeam="searchTeam"/>
          </div>
      </div>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
import ProjectBar_Level_2 from './ProjectBar_Level_2'
import DXcursorImg from "@/assets/img/Icon/cursorImg.png";
import iocnAdderss from '@/assets/img/Icon/sidebar_device_press.png';
export default {
  name: 'index',
  components:{
    ProjectBar_Level_2
  },
  data: function () {
    return {
      SpinShou: true,
      map: {},
      unfoldStatus: true,
      leftBarShou: true,
      searchMsg: '',
      searchShow: false,
      measureShow: false,
      stadiometryShou: true,
      mSurfaceShou: false,
      mouseTool: {},
      mapStyle1: '平面',
      mapStyle2: ['禁飞区'],
      hierarchyShou: false,
      satelliteLayer: {},
      roadNetLayer: {},
      infoWindow: {},
      MapSwitchIconShou: false,
      Shou3D: false,
      NFzone: [], // 禁飞区合集
      DateMarker:[]
    };
  },
  created() {
    this.SpinShou = false;
  },
  mounted() {
    //创建地图
    this.map = new AMap.Map('centerMap', {
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 16, //初始化地图层级
      center: [112.97935279, 28.21347823], //初始化地图中心点
    });
    // 修改主题样式
   // this.map.setMapStyle('amap://styles/whitesmoke');
    // 注册高德测量
    this.mouseTool = new AMap.MouseTool(this.map);
    //卫星图
    this.satelliteLayer = new AMap.TileLayer.Satellite();
    // 路网图
    this.roadNetLayer = new AMap.TileLayer.RoadNet();
    //默认不显示标注
    var features = ['bg', 'road', 'building'];
    this.map.setFeatures(features);
    //卫星地图
    this.map.add(this.satelliteLayer);
    //3D地图创建
    this.Init_map_cesium();

    // 初始化禁飞区
  //  this.initNFzone();
  },
  methods: {
    searchTeam(res){
      this.map.clearMap();
      let date=[]
      let  path=[]
      let list= res.data.coordinates.split('|')
      let height=res.data.ellipsoid_height.split('|')
      list.forEach(item=>{
        let e=item.split(',')
        path=[...path,[e[0]*1,e[1]*1]]
      })



      path.forEach((item,index)=>{
        let   dateList= wgs84togcj02(item[0],item[1])
        date=[...date,dateList]
        let labelContent="<div class='labelContentClass'>航点"+ (index+1) +" 高度 "+height[index]+"</div>"
        let B = new AMap.Marker({
          position:dateList,
          icon:require('@/assets/img/Marker/MediaImg.png'),
          offset: new AMap.Pixel(-30, -30),
          map: this.map,
          showPositionPoint: false,
          zIndex: 100,
          label: {
            direction: 'top',
            content: labelContent,
          }
        });
      //  this.map.add(B);
      })
      this.Polyline = new AMap.Polyline({
        path: date,
        showDir: true,
        strokeColor: '#0AEF8B', //线颜色
        strokeWeight: 4, //线宽
      });

     console.log('dateList',date)
      this.map.setCenter(date[0]);

      this.map.add(this.Polyline);

    },
    // 侧边栏伸缩动画
    unfold() {
      var width = this.$refs.leftBar.offsetWidth + 'px';
      if (this.unfoldStatus) {
        this.$refs.leftBar.style.left = `-${width}`;
        this.$refs.leftBar.style['transition'] = `left 1s`;
        this.$refs.unfoldBth.style.left = 0;
        this.$refs.unfoldBth.style['transition'] = `left 1s`;
        this.unfoldStatus = false;
      } else {
        this.$refs.leftBar.style.left = 0;
        this.$refs.leftBar.style['transition'] = `left 1s`;
        this.$refs.unfoldBth.style.left = width;
        this.$refs.unfoldBth.style['transition'] = `left 1s`;
        this.unfoldStatus = true;
      }
    },
    // 控件方法
    // 搜索
    searchShowFn() {
      this.searchShow = !this.searchShow;
      if (this.searchShow) {
        this.$refs.searchBar.style.opacity = 1;
      } else {
        this.$refs.searchBar.style.opacity = 0.6;
      }
    },
    search() {
      var _this = this;
      var geocoder = new AMap.Geocoder();
      var address = this.searchMsg;
      geocoder.getLocation(address, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location;
          // console.log("lnglat",lnglat);
          _this.map.setCenter([lnglat.lng, lnglat.lat]); //设置地图中心点
        } else {
          _this.$Message.error('根据地址查询位置失败');
        }
      });
    },
    // 测量绘制方法
    draw(type) {
      var _this = this;
      switch (type) {
        case 'rule': {
          _this.mouseTool.rule({
            startMarkerOptions: {
              //开始图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/start.png'),
              }),
            },
            endMarkerOptions: {
              //结束图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/end.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //中间点图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/mid.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //线的样式
              strokeStyle: 'solid',
              strokeColor: '#FF33FF',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          });
          break;
        }
        case 'measureArea': {
          _this.mouseTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          });
          break;
        }
      }
    },
    // 测量
    measureFn() {
      this.measureShow = !this.measureShow;
      this.mouseTool.close(true); //关闭，并清除覆盖物

      if (this.measureShow) {
        this.$refs.measureBar.style.opacity = 1;
        this.draw('rule'); //默认加载测距
      } else {
        this.$refs.measureBar.style.opacity = 0.6;
        this.mouseTool.close(true); //关闭，并清除覆盖物
        this.stadiometryShou = true;
        this.mSurfaceShou = false;
      }
      // 未完待续====================
    },
    // 测距
    stadiometryFn() {
      this.stadiometryShou = !this.stadiometryShou;
      this.mSurfaceShou = false;
      this.$refs.mSurface.style.opacity = 0.6;
      this.mouseTool.close(true); //关闭，并清除覆盖物
      if (this.stadiometryShou) {
        this.$refs.stadiometry.style.opacity = 1;
        this.draw('rule'); //开启测距
      } else {
        this.$refs.stadiometry.style.opacity = 0.6;
      }
    },
    // 测面积
    mSurfaceFn() {
      this.mSurfaceShou = !this.mSurfaceShou;
      this.stadiometryShou = false;
      this.$refs.stadiometry.style.opacity = 0.6;
      this.mouseTool.close(true); //关闭，并清除覆盖物
      if (this.mSurfaceShou) {
        this.$refs.mSurface.style.opacity = 1;
        this.draw('measureArea'); //开启测面
      } else {
        this.$refs.mSurface.style.opacity = 0.6;
      }
    },
    // 开关地图类型勾选列表
    hierarchyFn() {
      this.hierarchyShou = !this.hierarchyShou;
      console.log(this.hierarchyShou);
      if (this.hierarchyShou) {
        this.$refs.hierarchyBar.style.opacity = 1;
      } else {
        this.$refs.hierarchyBar.style.opacity = 0.6;
      }
    },
    // 放大
    zoomIn() {
      this.map.zoomIn();
    },
    // 缩小
    zoomOut() {
      this.map.zoomOut();
    },
    // 显示地图上的任务信息
    ShowMarker(listData, ProjectType) {
      this.infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });
      this.map.clearMap();
      //创建图标,绑定事件
      if (ProjectType == 3) {
        //当是显示任务列表
        listData.forEach((item) => {
          var iconImg;
          if (item.tasktype == 'airroute') {
            iconImg = require('@/assets/img/Marker/line_green.png');
          } else if (item.tasktype == 'figure') {
            iconImg = require('@/assets/img/Marker/pic_red.png');
          }

          if (item.firstlocal) {
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image: iconImg,
                size: new AMap.Size(25, 40),
              }),
              offset: new AMap.Pixel(-15, -40),
              position: [
                Number(eval(item.firstlocal).Lng),
                Number(eval(item.firstlocal).Lat),
              ],
            });
            marker.setLabel({
              offset: new AMap.Pixel(-50, -28),
              content: `<div class="mark_label"><P>${item.filetitle}</P></div>`,
            });
            marker.content = `<div class="marker-content" id=#${item.pm_id}>
                            <ul>
                                <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.filetitle}</li>
                                <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                                <li><i class="ivu-icon ivu-icon-md-person"></i>${item.name}</li>
                                <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入任务</li>
                            </ul>
                        </div>
                          `;
            marker.data = item;
            marker.id = item.pm_id;
            this.map.add(marker);
            marker.on('mouseover', this.shouInfoWindow);
          }
        });
        this.map.setFitView();
      } else {
        if (ProjectType == 1) {
          var iconImg = require('@/assets/img/Marker/Level_1_project.png');
        } else if (ProjectType == 2) {
          var iconImg = require('@/assets/img/Marker/Level_2_project.png');
        }

        listData.forEach((item) => {
          let marker = new AMap.Marker({
            icon: new AMap.Icon({
              image: iconImg,
              size: new AMap.Size(25, 40),
            }),
            offset: new AMap.Pixel(-15, -40),
            position: [
              Number(eval(item.location)[0].Lng),
              Number(eval(item.location)[0].Lat),
            ],
          });
          marker.setLabel({
            offset: new AMap.Pixel(-50, -28),
            content: `<div class="mark_label"><P>${item.pm_name}</P></div>`,
          });
          marker.content = `<div class="marker-content" id=#${item.pm_id}>
                            <ul>
                                <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.pm_name}</li>
                                <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                                <li><i class="ivu-icon ivu-icon-md-person"></i>${item.pm_administrator}</li>
                                <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入项目</li>
                            </ul>
                        </div>
                          `;
          marker.data = item;
          marker.id = item.pm_id;
          this.map.add(marker);
          marker.on('mouseover', this.shouInfoWindow);
        });
        if (ProjectType == 2) {
          this.map.setFitView();
        }
      }
    },
    // 移入显示详细信息
    shouInfoWindow(e) {
      var _this = this;
      this.infoWindow.setContent(e.target.content);
      this.infoWindow.open(this.map, e.target.getPosition());
      // 点击信息进入工程,任务
      setTimeout(function () {
        var a = document.getElementById(`#${e.target.id}`);
        a.onclick = function () {
          _this.IntoPproject(e.target.id, e.target.data);
        };
      }, 80);
    },
    //进入工程,任务
    IntoPproject(pm_id, e) {
      console.log('//进入工程,任务', pm_id, e, this.$route.name);
      if (this.$route.name == '一级工程列表详情') {
        console.log('进入一级工程,展示二级工程列表');
        this.$_bus.$emit('Into_1_project', e);
      } else if (this.$route.name == '二级工程列表详情') {
        console.log('进入二级工程,展示任务列表');
        this.$_bus.$emit('Into_2_project', e);
      } else if (this.$route.name == '任务列表详情') {
        console.log('进入任务', pm_id, e);
        if (e.tasktype == 'airroute') {
          //点击航线
          this.shouAirRoute(e, '航线');
        } else if (e.tasktype == 'figure') {
          //点击建图
          this.shouAirRoute(e, '建图');
        }
        this.IntoheTask(e);
      }
    },
    // 点击列表进入任务
    IntoheTask(item) {
      var _this = this;
      // console.log("点击列表进入任务",item);
      sessionStorage.setItem('getTaskData', JSON.stringify(item));
      this.$router.push({
        path: '/index/project/TaskBar',
      });
    },
    // 显示航线或建图
    shouAirRoute(Data, Type) {
      // console.log("显示航线",Data,Type);
      let _this = this;
      this.map.clearMap();
      if (Type == '航线') {
        this.MapSwitchIconShou = true;
        let waypoint = Data.waypoint;
        var path = [];
        waypoint.forEach((item) => {
          path.push([item.Lng, item.Lat]);
        });

        //画点

        AMapUI.loadUI(['overlay/SimpleMarker'], function (SimpleMarker) {
          path.forEach((item, ind) => {
            var simpleMarker = new SimpleMarker({
              iconLabel: {
                innerHTML: `<div>${ind + 1}</div>`,
                style: {
                  color: '#666', //设置文字颜色
                  width: '15px',
                  height: '15px',
                  borderRadius: '15px',
                  fontWeight: 'bold',
                  lineHeight: '13px',
                  top: '4px',
                },
              },
              //自定义图标节点(img)的属性
              iconStyle: {
                src: require('@/assets/img/Marker/MediaImg.png'),
                style: {
                  width: '60px',
                  height: '60px',
                },
              },
              //设置基点偏移
              offset: new AMap.Pixel(-30, -30),
              map: _this.map,
              showPositionPoint: false,
              position: item,
              zIndex: 100,
              // angle:0
            });
            simpleMarker.ind = ind;
            simpleMarker.DATA = Data;
            simpleMarker.on('click', _this.shouPicture);
          });
        });

        //画线
        var polyline = new AMap.Polyline({
          path: path,
          isOutline: true,
          outlineColor: '#fff',
          borderWeight: 0,
          strokeColor: '#4C98FF',
          strokeOpacity: 1,
          strokeWeight: 6,
          strokeStyle: 'solid',
          strokeDasharray: [10, 5],
          lineJoin: 'round',
          lineCap: 'round',
          zIndex: 50,
        });

        polyline.setMap(_this.map);
        // 缩放地图到合适的视野级别
        _this.map.setFitView([polyline]);
      } else if (Type == '建图') {
        let local_data = Data.local_data;
        var path = [];
        local_data.forEach((item) => {
          path.push([item.Lng, item.Lat]);
        });
        //画点
        path.forEach((item) => {
          let marker = new AMap.Marker({
            position: item,
          });
          marker.DATA = Data;
          this.map.add(marker);
          marker.on('click', this.shouOrthophotoMap);
        });

        // 画多边形
        var polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50,
        });
        polygon.DATA = Data;
        this.map.add(polygon);
        // 缩放地图到合适的视野级别
        this.map.setFitView([polygon]);
        polygon.on('click', this.shouOrthophotoMap);
      }
    },
    // 点击了航点 查看照片
    shouPicture(e) {
      this.$_bus.$emit('Into_Picture', e.target);
    },
    // 创建Cesium地图
    Init_map_cesium() {
      // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
      Cesium.Ion.defaultAccessToken = Api.CesiumToken();
      //镜头默认范围(必须在viewer实例创建前设置)
      // 定位在中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        73.0,
        3.0,
        135.0,
        53.0
      );

      let viewer = new Cesium.Viewer('center3DMap', {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: true, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
      });
      // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      window.Viewer_airroute = viewer;
      // 去掉版权信息
      viewer.cesiumWidget.creditContainer.style.display = 'none';

      //google卫星影像
      var gog = new Cesium.UrlTemplateImageryProvider({
        url: Api.googleMap(),
      });
      viewer.imageryLayers.addImageryProvider(gog);

      // 开启地形深度检测
      viewer.scene.globe.depthTestAgainstTerrain = true;

      //加载地形
      // viewer.terrainProvider = Cesium.createWorldTerrain();

      // home定位到具体地点和高度(长沙)
      viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        function (e) {
          e.cancel = true;
          //你要飞的位置
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              119.286808,
              26.053644,
              50000
            ),
          });
        }
      );

      //阻止摄像头到地下(还有缺陷)
      // viewer.clock.onTick.addEventListener(function () {
      //   if (viewer.camera.pitch > 0) {
      //     viewer.scene.screenSpaceCameraController.enableTilt = false;
      //   }
      // });
      //
      // var mousePosition, startMousePosition;
      // var handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      // handler.setInputAction(function (movement) {
      //   mousePosition = startMousePosition = Cesium.Cartesian3.clone(movement.position);
      //   handler.setInputAction(function (movement) {
      //     mousePosition = movement.endPosition;
      //     var y = mousePosition.y - startMousePosition.y;
      //     if (y > 0) {
      //       viewer.scene.screenSpaceCameraController.enableTilt = true;
      //     }
      //   }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      // }, Cesium.ScreenSpaceEventType.RIGHT_DOWN);
    },
    // 显示3D航线或建图
    shou3DAirRoute(Data, Type) {
      let _this = this;
      let viewer = window.Viewer_airroute;
      viewer.entities.removeAll();

      if (Type == '航线') {
        let waypoint = Data.waypoint;
        var path = [];
        // console.log("waypoint",waypoint);
        waypoint.forEach((item) => {
          path.push([item.Lng, item.Lat, item.altitude]);
        });

        // home定位到项目地点和高度
        // 高德坐标转WGS84
        let Location = gcj02towgs84(path[0][0], path[0][1]);
        // console.log("Locatio",Location);
        viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
          function (e) {
            e.cancel = true;
            //你要飞的位置
            viewer.camera.flyTo({
              destination: Cesium.Cartesian3.fromDegrees(
                Location[0],
                Location[1],
                1000
              ),
            });
          }
        );

        let WGS84_path = [];

        // 添加项目图标
        path.forEach((item, ind) => {
          // 高德坐标转WGS84
          let markerLocation = gcj02towgs84(item[0], item[1]);
          let lng = markerLocation[0];
          let lat = markerLocation[1];
          WGS84_path.push(markerLocation);

          viewer.entities.add({
            // id:Data.id,
            name: '航点',
            position: Cesium.Cartesian3.fromDegrees(lng, lat, item[2]),
            // polyline : {// 画线
            //   positions : Cesium.Cartesian3.fromDegreesArrayHeights(
            //           [ lng,lat,0, lng,lat,item[2]]
            //   ),
            //   width : 1,
            //   material : new Cesium.PolylineOutlineMaterialProperty({
            //     color : Cesium.Color.RED,
            //     outlineWidth : 0,
            //     // outlineColor : Cesium.Color.BLACK
            //   })
            // },
            // billboard : { //图标
            //   image : require('../../assets/img/Marker/uav.png')
            // },
            model: {
              // 模型路径
              uri: require('@/assets/glb/aaa.glb'),
              // 模型最小刻度
              // minimumPixelSize: 128,
              // 模型最大刻度
              // maximumSize: 128,
              //设置模型最大放大大小
              // maximumScale : 200,
              // 模型是否可见
              show: true,
              // 模型轮廓颜色
              // silhouetteColor:Cesium.Color.WHITE,
              // 模型颜色  ，这里可以设置颜色的变化
              // color: Cesium.Color.LIME ,
              // 仅用于调试，显示魔仙绘制时的线框
              debugWireframe: false,
              // 仅用于调试。显示模型绘制时的边界球。
              debugShowBoundingVolume: false,
              scale: 50,
              //是否运行模型中的动画效果
              runAnimations: false,
            },
            label: {
              //定义标签title
              text: '航点 : ' + (ind + 1),
              font: 'normal 14px MicroSoft YaHei',
              scale: 0.8,
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              color: Cesium.Color.WHITE,
              showBackground: true,
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(0, -30),
            },
            DATA: Data,
            ind: waypoint[ind].order,
          });
        });
        waypoint.forEach((item, ind) => {
          WGS84_path[ind].push(Number(item.altitude));
        });
        let positions = [];
        WGS84_path.forEach((item) => {
          positions.push(item[0], item[1], item[2]);
        });
        // console.log("画线的路径",positions);
        // 画线
        viewer.entities.add({
          // id:item.pm_id,
          name: '航线',
          polyline: {
            // 画线
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
            width: 2,
            material: new Cesium.PolylineOutlineMaterialProperty({
              color: new Cesium.Color(47 / 255, 204 / 255, 113 / 255, 1),
              outlineWidth: 0,
              // outlineColor : Cesium.Color.BLACK
            }),
          },
        });

        viewer.zoomTo(viewer.entities);

        //自定义单击事件
        viewer.screenSpaceEventHandler.setInputAction(function (click) {
          // 处理鼠标按下事件，获取鼠标当前位置
          // console.log(click);
          var feature = viewer.scene.pick(click.position);
          //选中某模型
          // console.log("feature.DATA",feature);
          if (feature && feature.id.DATA) {
            _this.shouPicture_3D(feature.id);
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      } else if (Type == '建图') {
      }
    },
    // 点击了3D航点 查看照片
    shouPicture_3D(data) {
      this.$_bus.$emit('Into_Picture', data);
    },
    // 切换地图
    Map3DSwitch() {
      this.Shou3D = !this.Shou3D;
      if (this.Shou3D) {
      }
    },
    //隐藏3D地图
    close3DMap() {
      this.Shou3D = false;
      this.MapSwitchIconShou = false;
    },
    //显示正射地图
    shouOrthophotoMap(e) {
      this.$_bus.$emit('Into_OrthophotoMap', e.target);
    },

    /**
     * 禁飞区 NFzone
     */
    // 初始化禁飞区
    initNFzone() {
      let that = this;
      this.$post('/base/common/getNotFlyArea', {
        client: 'web',
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = [];
            element.local_data.forEach((ele) => {
              path.push(new AMap.LngLat(ele.lng, ele.lat));
            });
            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            });
            that.NFzone.push(polygon);
            that.map.add(polygon);
          });
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == '/index/project/TaskBar') {
        console.log('当前路由是航线页面');
        // this.MapSwitchIconShou=true
      } else {
        this.MapSwitchIconShou = false;
      }
    },
    mapStyle1(val) {
      //卫星平面切换
      if (val == '平面') {
        this.map.remove(this.satelliteLayer);
      } else if (val == '卫星') {
        this.map.add(this.satelliteLayer);
      }
    },
    mapStyle2(val) {
      //路网,禁飞区显示
      // 路网
      if (val.indexOf('路网图') !== -1) {
        this.map.add(this.roadNetLayer);
      } else {
        this.map.remove(this.roadNetLayer);
      }
      // 禁飞区
      if (val.indexOf('禁飞区') !== -1) {
        this.NFzone.forEach((element) => {
          element.show();
        });
      } else {
        this.NFzone.forEach((element) => {
          element.hide();
        });
      }
      //显示默认标注
      if (val.indexOf('地名') !== -1) {
        var features = ['bg', 'road', 'building', 'point'];
        this.map.setFeatures(features);
      } else {
        var features = ['bg', 'road', 'building'];
        this.map.setFeatures(features);
      }
    },
  },
};
</script>

<style scoped lang="scss">
#project {
  width: 100%;
  height: 100%;
  position: absolute;
  .centerMap {
    width: 100%;
    height: 100%;
  }
  .center3DMap {
    width: 100%;
    height: 100%;
  }
  /deep/.amap-marker-label {
    //修改高德默认label样式
    background-color: #fff;
    text-align: center;
    width: 120px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    padding: 0 4px;

    .mark_label {
      p {
        line-height: 25px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  /deep/.amap-info-contentContainer {
    .amap-info-content {
      border-radius: 10px;
      padding: 15px 35px 15px 25px;
      .marker-content {
        li {
          line-height: 20px;
          i {
            margin-right: 6px;
            font-size: 16px;
          }
        }
      }
    }
    .amap-info-close {
      right: 10px !important;
      top: 10px !important;
    }
  }

  .mapControl {
    width: 50px;
    max-height: 400px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    .item {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #111;
      opacity: 0.6;
      cursor: pointer;
      margin-bottom: 16px;
      position: relative;
      img {
        width: 20px;
      }
      .measureBox {
        position: absolute;
        top: 0;
        left: -220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          width: 100px;
          height: 60px;
          background-color: #333;
          opacity: 0.6;
          border-radius: 10px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 20px;
            color: #fff;
          }
        }
        :nth-child(1) {
          opacity: 1;
        }
      }
      .hierarchyBox {
        z-index: 1000 !important;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: -90px;
        background-color: #333;
        padding: 10px;
        > div {
          margin-bottom: 6px;
          input {
            float: left;
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
          span {
            float: right;
            font-size: 12px;
            color: #fff;
            line-height: 16px;
          }
        }
        hr {
          margin: 15px 0;
        }
      }
    }
    .item:hover {
      opacity: 1;
    }
  }
  .MapSwitch {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #333;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 50px;
    img {
      width: 80%;
      height: auto;
    }
  }
  #Map3D {
    opacity: 1;
  }
  .unfoldBth {
    width: 20px;
    height: 120px;
    background-color: #4c98ff;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 340px;
    @include ct;
    img {
      width: 14px;
      height: 24px;
    }
    i {
      font-size: 25px;
      color: #fff;
    }
  }
  .searchBox {
    @include cl;
    top: 30px;
    width: 500px;
    height: 50px;
    /deep/.ivu-input {
      height: 40px;
      border-radius: 50px;
      border: none;
      padding-left: 55px;
      box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
    }
    /deep/.ivu-input:focus {
      outline: none;
      box-shadow: 0 0 0 2px transparent;
      border: 1px solid #409eff;
    }
    /deep/.ivu-input-prefix,
    .ivu-input-suffix {
      width: 60px;
      height: 40px;
      line-height: 40px;
      i {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }
  .leftBar {
    width: 340px;
    height: 100%;
    background-color: #fafafa;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 1000 !important;
  }
  .leftBar::-webkit-scrollbar {
    display: none;
  }
}
</style>
