<template>
  <!--<div id="ProjectBar" @scroll="scrollEvent" ref="ProjectBar">-->
    <div id="ProjectBar">
    <!--用于设置跳转锚点-->
<!--    <div id="topAnchor"></div>-->
    <!--返回-->
    <!--新建项目-->
    <!--搜索项目-->
<!--    <div class="searchProject">
      <i class="ivu-icon ivu-icon-ios-search"></i>
      <input type="text"
             v-model="searchMsg"
             placeholder="搜索"
             @keyup.enter="searchProjectFn">
    </div>-->
    <!--选择团队-->
    <div class="team clearfix">
      <h3>航线</h3>
      <div class="teamBox">
        <div class="teamItem" v-for="(item,index) in teamData" :key="index" @click="searchTeam(item)">
          <span>{{item.name}}</span>
        </div>
      </div>
      <div style="text-align: right;margin:20px;" v-if="teamData.length>0">
        <Page :total="total" size="small"  :page-size="page_size" @on-change="pageBut"/>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'ProjectBar',
  props: {
    ShowMarker: {
      type: Function,
      default: null,
    },
    shouAirRoute: {
      type: Function,
      default: null,
    },
    DateMarker:{
      String: Array,
    }
  },
  data: function () {
    return {
      searchMsg:'',
      teamData: [], //列表
      workspaceId:'',
      total:0,
      page:1,
      page_size:10  //
    };
  },
  mounted() {
    this.workspaceId=  sessionStorage.getItem('sy-workspace_id');
    this.getWaylines()
 /*   this.map = new AMap.Map(this.id, {
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 14, //初始化地图层级
      // center: this.$Center, //初始化地图中心点
      // 是否使用高精度定位，默认：true
      enableHighAccuracy: true

    });*/
  },
  methods: {
    pageBut(e){
      this.page=e
      this.getWaylines()
    },
    getWaylines(){
      this.$sy_get('wayline/api/v1/workspaces/'+this.workspaceId+'/waylines?order_by=update_time desc&page='+this.page+'&pageSize='+this.page_size).then(res=>{
     /*this.$get('/pilot/'+this.workspaceId+'/waylines?order_by=update_time desc&page='+this.page+'&pageSize='+this.page_size).then(res=>{*/
        this.teamData=res.data.list
        this.total=res.data.pagination.total
        console.log(res)
      })
    },
    searchTeam(item){
      let workspaceId=this.workspaceId
      let id=item.id
      console.log('item',item)
      this.$sy_get('wayline/api/v1/workspaces/'+workspaceId+'/waylines/lineData?workspace_id='+id).then(res=>{
     /* this.$get('/pilot/'+workspaceId+'/waylines/lineData?wayline_id='+id).then(res=>{*/
        console.log(res)
        this.$emit('searchTeam',res)
      })

    }

  },
  watch: {

  },
};
</script>

<style scoped lang="scss">
#ProjectBar {
  position: relative;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #1F1F1F;
  .getBack {
    background-color: #1F1F1F;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;
    i {
      font-size: 25px;
      color: #ffffff;
      margin-right: 6px;
      line-height: 50px;
    }
    span {
      font-size: 20px;
      color: #ffffff;
      line-height: 50px;
    }
  }
  .newProject {
    width: 320px;
    height: 86px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    padding-left: 30px;
    .content {
      display: flex;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
        margin-right: 20px;
      }
      .text {
        h1 {
          font-size: 18px;
          color: #111;
          background-color: #fff;
          font-weight: bold;
        }
        h2 {
          font-size: 12px;
          color: #999;
        }
      }
    }
    &:hover {
      border: 1px solid #409eff;
    }
  }

  .searchProject {
    width: 320px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    input {
      width: 100%;
      height: 40px;
      font-size: 12px;
      color: #999;
      border: none;
      background-color: #fafafa;
      border-bottom: 1px solid #dcdee2;
      padding: 0 30px;
      text-align: center;
    }
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }
    i {
      position: absolute;
      top: 10px;
      left: 0;
      font-size: 26px;
      color: #dcdee2;
    }
  }
  .team {
    width: 320px;
    margin-top: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    h3 {
      font-size: 20px;
      color: #fff;
      margin-bottom: 10px;
    }
    .teamBox {
      .teamItem{
        width:100%;
        line-height: 40px;
        border: 1px solid #383838;
        background: #383838;
        color: #fff;
        font-size: 14px;
        text-indent: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .teamItem:hover{
        border: 1px solid #29b8f8;
        color: #29b8f8;
      }
    }
  }
  .ProjectList {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    h1 {
      font-size: 12px;
      color: #c4c4c4;
      margin-bottom: 10px;
      margin-right: 20px;
      position: relative;
      float: right;
      i {
        position: absolute;
        top: -3px;
        left: 21px;
        font-size: 25px;
        color: #c4c4c4;
      }
    }
    .ListItem {
      width: 100%;
      height: 100px;
      padding: 0 10px 0 20px;
      background-color: #fff;
      border-width: 1px 1px 1px;
      border-style: solid;
      border-color: #fff #fff #f3f3f3;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 2px;
      position: relative;
      .ItemLeft {
        width: 80px;
        height: 100%;
        position: relative;
        margin-right: 16px;
        img {
          width: 37px;
          height: auto;
          @include center;
        }
      }
      .ItemContent {
        width: 210px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 16px;
          color: #111;
          font-size: 16px;
          color: #333;
        }
        h3 {
          width: 100%;
          font-size: 12px;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .belongToTeam {
          .TeamColor {
            float: left;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 4px;
            margin-top: 6px;
          }
        }
      }
      .ItemRight {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        .more_i {
          display: block;
          cursor: pointer;
          width: 30px;
          line-height: 34px;
          font-size: 30px;
          color: #ccc;
        }
        .api {
          margin: 4px 0;
          .Divider {
            width: 100%;
            height: 1px;
            margin: 6px 0;
            background-color: #c5c8ce;
          }
          .Button {
            cursor: pointer;
          }
        }
      }
    }
    .ListItem:hover {
      border: 1px solid #409eff;
    }
  }
  .goTop {
    width: 80px;
    height: 30px;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    line-height: 30px;
    position: fixed;
    bottom: 20px;
    left: 80px;
    cursor: pointer;
    display: none;
  }
  /deep/.ivu-poptip-popper {
    padding-left: 55px;
  }
}
#ProjectBar::-webkit-scrollbar {
  display: none;
}
</style>
